export const postRegistrationData = async (firstName, lastName, discordHandle, uniqueID, macAddress) => {
    const response = await fetch(`${process.env.API_URL}/bot/register`,
        {
            method: "POST",
            body: JSON.stringify({
                "first_name": firstName,
                "last_name": lastName,
                "discord_handle": discordHandle,
                "uuid": uniqueID,
                "mac_address": macAddress
            }),
            headers: {
                'Content-Type': 'application/json'
            }
        })
    return await response.json()
}

export const getAccountingDataCount = async (table) => {
    const response = await fetch(`${process.env.API_URL}/table/${table}/count`,
        {
            method: "GET",
            headers: {}
        })
    return await response.json()
}

export const getAccountingData = async (table, currentPage) => {
    const pageSize = 10;
    const response = await fetch(`${process.env.API_URL}/table/${table}?page=${currentPage}&pageSize=${pageSize}`,
        {
            method: "GET",
            headers: {}
        })
    return await response.json()
}

/* REPORTS */
const REPORT_PREFIX = "report"
export const getRegisteredBadgeCount = async () => {
    const response = await fetch(`${process.env.API_URL}/${REPORT_PREFIX}/badge/registration/count`,
        {
            method: "GET",
            headers: {}
        })
    return await response.json()
}

export const getTopBreathalyzerReading = async () => {
    const response = await fetch(`${process.env.API_URL}/${REPORT_PREFIX}/breathalyzer/top`,
        {
            method: "GET",
            headers: {}
        })
    return await response.json()
}

export const getAverageBreathalyzerReading = async () => {
  const response = await fetch(`${process.env.API_URL}/${REPORT_PREFIX}/breathalyzer/average`,
    {
      method: "GET",
      headers: {}
    })
  return await response.json()
}

export const getUnclaimedAchievementCount = async () => {
    const response = await fetch(`${process.env.API_URL}/${REPORT_PREFIX}/achievements/unclaimed`,
        {
            method: "GET",
            headers: {}
        })
    return await response.json()
}

export const getHiddenAchievementFoundCount = async () => {
    const response = await fetch(`${process.env.API_URL}/${REPORT_PREFIX}/achievements/hidden/found`,
        {
            method: "GET",
            headers: {}
        })
    return await response.json()
}

export const getEarnedAchievementSum = async () => {
    const response = await fetch(`${process.env.API_URL}/${REPORT_PREFIX}/achievements/users/sum`,
        {
            method: "GET",
            headers: {}
        })
    return await response.json()
}

export const getGamesPlayedCount = async () => {
    const response = await fetch(`${process.env.API_URL}/${REPORT_PREFIX}/games/users/total`,
        {
            method: "GET",
            headers: {}
        })
    return await response.json()
}

/* LEADERBOARD */
const GAME_PREFIX = "games"
const LEADERBOARD_PREFIX = "leaderboard"

export const getGamesListForDropdown = async () => {
    const response = await fetch(`${process.env.API_URL}/${GAME_PREFIX}/list`,
        {
            method: "GET",
            headers: {}
        })
    return await response.json()
}

export const getFilteredGamesForLeaderboard = async (currentPage, gameName, discordName) => {
    const pageSize = 10;

    const userParams = []
    let extra = ""
    if (gameName !== undefined && gameName !== "" && gameName !== null) {
        userParams.push(`gameName=${gameName}`)
    }
    if (discordName !== undefined && discordName !== "" && discordName !== null) {
        userParams.push(`discordName=${discordName}`)
    }
    if (userParams.length > 0) {
        extra = `&${userParams.join("&")}`
    }


    const response = await fetch(`${process.env.API_URL}/${LEADERBOARD_PREFIX}?page=${currentPage}&pageSize=${pageSize}${extra}`,
        {
            method: "GET",
            headers: {}
        })
    return await response.json()
}

/* SCHEDULE */
const SCHEDULE_PREFIX = "schedule"

export const getEventSchedule = async () => {
    const response = await fetch(`${process.env.API_URL}/${SCHEDULE_PREFIX}/events`,
        {
            method: "GET",
            headers: {}
        })
    return await response.json()
}

export const getTalkSchedule = async () => {
    const response = await fetch(`${process.env.API_URL}/${SCHEDULE_PREFIX}/talks`,
        {
            method: "GET",
            headers: {}
        })
    return await response.json()
}

export const getWorkshopSchedule = async () => {
    const response = await fetch(`${process.env.API_URL}/${SCHEDULE_PREFIX}/workshops`,
        {
            method: "GET",
            headers: {}
        })
    return await response.json()
}
