import React, { useState, useEffect } from 'react';
import { Card } from 'semantic-ui-react';

export const CountCard = ({ header, fetchData, keyToRetrieveCount }) => {
    const [count, setCount] = useState(0);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchCount = async () => {
            try {
                const data = await fetchData();
                setCount(data[0][keyToRetrieveCount]); // Adjust based on the expected data shape
            } catch (error) {
                console.error(`Failed to fetch ${header.toLowerCase()}:`, error);
                setError(true);
            }
        };

        fetchCount();
    }, [fetchData, header]);

    return (
        <Card>
            <Card.Content>
                <Card.Header>{header}</Card.Header>
                <Card.Description>{error ? 'Error fetching data' : count}</Card.Description>
            </Card.Content>
        </Card>
    );
};
