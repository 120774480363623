import React, {useEffect, useState} from "react";
import {getFilteredGamesForLeaderboard, getGamesListForDropdown} from "../api"
import {Dropdown, Input, Pagination} from 'semantic-ui-react';
import {Button, Card, Container, Icon, Table, Label} from "semantic-ui-react";
import "../filter.css"

const Leaderboard = () => {
    // the key with this type of setup is that we need a line & f(x) for each input
    const [leaderboardData, setLeaderboardData] = useState([]);
    const [leaderboardCount, setLeaderboardCount] = useState(0);
    const [activeLeaderboardPage, setActiveLeaderboardPage] = useState(1);
    const [selectedGame, setSelectedGame] = useState(null);
    const [discordName, setDiscordName] = useState('');
    const [gameOptions, setGameOptions] = useState([]);

    useEffect(() => {
        const fetchGames = async () => {
            try {
                const games = await getGamesListForDropdown();
                // Transform the games list to the format expected by the dropdown
                const options = games.map(game => ({
                    key: game.id,
                    text: game.game_name,
                    value: game.id,
                }));
                options.unshift({
                    key: 'reset',
                    text: 'All Games',
                    value: '',
                });
                setGameOptions(options);
            } catch (error) {
                console.error('Failed to fetch games:', error);
            }
        };

        fetchGames();
    }, []);

    useEffect(() => {
        getFilteredGamesForLeaderboard(1)
            .then((response) => {
                if (response.length > 0) {
                    setLeaderboardCount(response[0]['total'])
                    setLeaderboardData(response)
                }
            })
    }, []);

    const handleGameChange = (e, { value }) => {
        setSelectedGame(value);
    };

    const handleDiscordNameChange = (e) => {
        setDiscordName(e.target.value);
    };

    const handleSubmit = (e, pageInfo) => {
        setActiveLeaderboardPage(1)
        getFilteredGamesForLeaderboard(1, selectedGame, discordName)
            .then((response) => {
                if (response.length > 0) {
                    setLeaderboardCount(response[0]['total'])
                    setLeaderboardData(response)
                }
            });
    };

    const pageHandler = (e, pageInfo) => {
        setActiveLeaderboardPage(pageInfo.activePage)
        getFilteredGamesForLeaderboard(pageInfo.activePage, selectedGame, discordName)
            .then((response) => {
                if (response.length > 0) {
                    setLeaderboardCount(response[0]['total'])
                    setLeaderboardData(response)
                }
            })
    };


    const registrationDataColumns = () => {
        return [
            {
                header: "id",
                accessor: "gs_id",
                visible: false
            },
            {
                header: "Game Name",
                accessor: "game_name",
                visible: true
            },
            {
                header: "Discord",
                accessor: "discord_handle",
                visible: true
            },
            {
                header: "Score",
                accessor: "score",
                visible: true
            },
            {
                header: "Duration",
                accessor: "duration",
                visible: true,
                transform: (value) => {
                    // Convert milliseconds to minutes, seconds, and milliseconds
                    const minutes = Math.floor(value / (1000 * 60));
                    const seconds = Math.floor((value % (1000 * 60)) / 1000);
                    const milliseconds = Math.floor((value % 1000));

                    const formattedMinutes = String(minutes).padStart(2, '0');
                    const formattedSeconds = String(seconds).padStart(2, '0');
                    const formattedMilliseconds = String(milliseconds).padStart(3, '0');

                    return `${formattedMinutes}:${formattedSeconds}.${formattedMilliseconds}`;
                }
            }
        ]
    }

    const buildTableHeaders = () => {
        let columns = registrationDataColumns()
        return columns.map((col) => {
            if (col['visible']) {
                return (
                    <Table.HeaderCell>{col['header']}</Table.HeaderCell>
                )
            }
        });
    }

    const buildTableData = (props) => {
        let columns = registrationDataColumns()
        const data = props['data']
        if (!!data && data.length > 0) {
            return data.map((d, i) => {
                let rowId = d['gs_id']
                let cellData = columns.map((col) => {
                    let keyId = `${d['gs_id']}${d[col['accessor']]}`
                    let value = d[col['accessor']]
                    if (col['transform']) {
                        value = col['transform'](value)
                    }
                    if (col['visible']) {
                        return (
                            <Table.Cell key={keyId}>{value}</Table.Cell>
                        )
                    }
                });
                return (
                    <Table.Row key={rowId}>{cellData}</Table.Row>
                )
            })
        }
    }

    const TableItUp = (data) => (
        <Table celled>
            <Table.Header>
                <Table.Row>
                    {buildTableHeaders()}
                </Table.Row>
            </Table.Header>

            <Table.Body>
                {buildTableData(data)}
            </Table.Body>
        </Table>
    )

    return (
        <Container>
            <div className="filter-section d-flex flex-column align-items-center">
                <h3 className="filter-header">Badge Leaderboard</h3>
                <div className="filter-controls">
                    <Dropdown
                        placeholder='Select Game'
                        fluid
                        selection
                        options={gameOptions}
                        onChange={handleGameChange}
                        className="filter-dropdown"
                    />
                    <Input
                        action={{
                            icon: 'search'
                        }}
                        placeholder='Discord Name...'
                        value={discordName}
                        onChange={handleDiscordNameChange}
                        className="filter-input"
                    />
                    <Button onClick={handleSubmit}>Search</Button>
                </div>
            </div>
            <div>
                <div>
                    <TableItUp data={leaderboardData}/>
                </div>
                <div className="ui right aligned container">
                    <Pagination
                        activePage={activeLeaderboardPage}
                        onPageChange={pageHandler}
                        totalPages={Math.ceil(leaderboardCount / 10)}
                        ellipsisItem={null}
                    />
                </div>
            </div>
        </Container>
    )
}



export default Leaderboard;