import React, { useState, useEffect } from 'react';
import {Card, Segment} from 'semantic-ui-react';

export const ScheduleSegmentItems = ({ fetchData }) => {
    const [events, setEvents] = useState([]);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const data = await fetchData(); // Fetch the data from the API
                setEvents(data); // Store the fetched data in the state
            } catch (error) {
                console.error(`Failed to fetch data:`, error);
                setError(true); // Set error state to true if fetching fails
            }
        };

        fetchEvents();
    }, [fetchData]);

    const handleSegmentClick = () => {
        window.open('https://cackalackycon.org/schedule.html');
    };

    // Render event for TALK type
    const renderTalkEvent = (event) => {
        const formattedTime = event.event_start_time.substring(0, 5);
        return (
            <>
                {formattedTime} | {event.name} by {event.speaker_name}<br/> @ <b>{event.location}</b>
            </>
        );
    }

    // Render event for EVENT or WORKSHOP type
    const renderEventOrWorkshop = (event) => {
        const formattedTime = event.event_start_time.substring(0, 5);
        return (
            <>
                {formattedTime} | {event.name}<br/> @ <b>{event.location}</b>
            </>
        )
    };


    if (error) {
        return <Segment>Error loading events</Segment>; // Display error message if error occurred
    }

    return (
        <div>
            {events.length > 0 ? (
                events.map((event, index) => (
                    <Segment key={index} onClick={handleSegmentClick} style={{ cursor: 'pointer' }}>
                        {event.type === 'talk' ? renderTalkEvent(event) : renderEventOrWorkshop(event)}
                    </Segment>
                ))
            ) : (
                <Segment>Loading...</Segment> // Show loading message if no events are loaded yet
            )}
        </div>
    );
};
