import React, { useState } from 'react';
import { Menu } from 'semantic-ui-react'

const NavigationBar = () => {

    return (
        <Menu pointing secondary>
            <Menu.Item  name='home' href="/"/>
            <Menu.Item  name='activities' href="/activities"/>
        </Menu>
    )
}

export default NavigationBar;