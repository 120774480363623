import React from "react";
import {Container, Card, Grid, Segment } from "semantic-ui-react";
import {
    getAverageBreathalyzerReading,
    getEventSchedule,
    getHiddenAchievementFoundCount,
    getRegisteredBadgeCount, getTalkSchedule,
    getTopBreathalyzerReading,
    getUnclaimedAchievementCount, getWorkshopSchedule
} from '../api';
import {CountCard} from "./CountCard";
import {ScheduleSegmentItems} from "./ScheduleSegmentItems";

const BadgeCountCard = () => (
    <CountCard
        header="Registered Badge Count"
        fetchData={getRegisteredBadgeCount}
        keyToRetrieveCount="crb_count"
    />
);

const TopBreathalyzerCard = () => (
    <CountCard
        header="Top Breathalyzer Reading"
        fetchData={getTopBreathalyzerReading}
        keyToRetrieveCount="reading"
    />
);

const AvgBreathalyzerCard = () => (
  <CountCard
    header="Avg Breathalyzer Reading"
    fetchData={getAverageBreathalyzerReading}
    keyToRetrieveCount="avg"
  />
);

const UnclaimedAchievementsCard = () => (
    <CountCard
        header="Total Unclaimed Achievements"
        fetchData={getUnclaimedAchievementCount}
        keyToRetrieveCount="result"
    />
);

const HiddenAchievementFoundCard = () => (
    <CountCard
        header="Sum of Hidden Achievements Found"
        fetchData={getHiddenAchievementFoundCount}
        keyToRetrieveCount="hidden_achievement_found"
    />
);

const EventsSegment = () => (
    <ScheduleSegmentItems
        fetchData={getEventSchedule}
    />
);

const TalksSegment = () => (
    <ScheduleSegmentItems
        fetchData={getTalkSchedule}
    />
);

const WorkshopsSegment = () => (
    <ScheduleSegmentItems
        fetchData={getWorkshopSchedule}
    />
);

const Home = () => {
    return (
        <Container>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={5}>
                        <Segment>
                            <h3>Event Schedule</h3>
                            <Segment.Group>
                                <EventsSegment/>
                            </Segment.Group>
                        </Segment>
                        <Segment>
                            <h3>Workshop Schedule</h3>
                            <Segment.Group>
                                <WorkshopsSegment/>
                            </Segment.Group>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column width={7}>
                        <Segment>
                            <h3>Talk Schedule</h3>
                            <Segment.Group>
                                <TalksSegment/>
                            </Segment.Group>
                        </Segment>
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <Card.Group>
                            <BadgeCountCard/>
                            <TopBreathalyzerCard />
                            <AvgBreathalyzerCard />
                            <UnclaimedAchievementsCard />
                            <HiddenAchievementFoundCard />
                        </Card.Group>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    )
}

export default Home;