import './App.css';
import 'semantic-ui-css/semantic.min.css'
import React from "react";
import Home from "./components/Home";
import Accounting from "./components/Accounting";
import NavigationBar from "./components/Navbar";
import {
    BrowserRouter,
    Route,
    Routes
} from "react-router-dom"
import Activities from "./components/Activities";

function App() {
  return (
    <div className={'container-fluid'}>
        <BrowserRouter>
            <NavigationBar />
            <Routes>
                <Route exact path="/" element={<Home />} />
                <Route exact path="/activities" element={<Activities />} />
            </Routes>
        </BrowserRouter>
    </div>
  );
}



export default App;
