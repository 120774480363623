import React, {useState, useEffect} from 'react';
import { Grid, Segment, Dropdown, Input } from 'semantic-ui-react';
import {StatisticComp} from "./Statistic";
import {getEarnedAchievementSum, getGamesListForDropdown, getGamesPlayedCount} from "../api";
import Leaderboard from "./LeaderboardTable";


const AchievementSumAllStatistic = () => (
    <StatisticComp
        header="Earned Achievement Points"
        fetchData={getEarnedAchievementSum}
        keyToRetrieveCount="sum_unlocked_pts"
    />
);

const TotalGamesPlayed = () => (
    <StatisticComp
        header="Total Games Played"
        fetchData={getGamesPlayedCount}
        keyToRetrieveCount="total_games_played"
    />
);


const LeaderboardComponent = () => {
    return (
        <Grid>
            <Grid.Row columns={2} style={{ display: 'flex', alignItems: 'center' }}>
                <Grid.Column textAlign={"center"}>
                    <AchievementSumAllStatistic />
                </Grid.Column>
                <Grid.Column textAlign={"center"}>
                    <TotalGamesPlayed />
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Segment>
                        <Leaderboard />
                    </Segment>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
};

export default LeaderboardComponent;
