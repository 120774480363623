import React, { useState, useEffect } from 'react';
import { Statistic } from 'semantic-ui-react';

export const StatisticComp = ({ header, fetchData, keyToRetrieveCount }) => {
    const [count, setCount] = useState(0);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchCount = async () => {
            try {
                const data = await fetchData();
                setCount(data[0][keyToRetrieveCount]); // Adjust based on the expected data shape
            } catch (error) {
                console.error(`Failed to fetch ${header.toLowerCase()}:`, error);
                setError(true);
            }
        };

        fetchCount();
    }, [fetchData, header]);

    return (
        <Statistic>
            <Statistic.Value>{error ? 'Error fetching data' : count}</Statistic.Value>
            <Statistic.Label>{header}</Statistic.Label>
        </Statistic>
    );
};
